import { Link } from "gatsby";
import * as React from "react";

import { Footer, Page } from "../components";
import * as css from "../styles/pages/regulations.module.scss";

const PrivacyPolicy = () => {
  return (
    <Page metaTitle="Polityka prywatności - Beautiful Places">
      <div className={css.wrapper}>
        <h1>Polityka prywatności</h1>
        <p>
          SmartWeb stworzył aplikację <span>Beautiful Places</span> (zwana dalej
          USŁUGĄ) jako aplikację bezpłatną. Usługa jest dostarczana przez
          SmartWeb bez żadnych kosztów i jest przeznaczona do użytku w takim
          stanie, w jakim się znajduje.
        </p>

        <p>
          Ta strona służy do informowania odwiedzających o naszych zasadach
          dotyczących zbierania, wykorzystywania i ujawniania danych osobowych,
          jeśli ktoś zdecydował się na korzystanie z naszego Serwisu.
        </p>

        <p>
          Jeśli użytkownik zdecyduje się na korzystanie z naszych Usług, wyraża
          zgodę na gromadzenie i wykorzystywanie informacji w związku z
          niniejszymi zasadami. Gromadzone przez nas Informacje osobiste są
          wykorzystywane do świadczenia i ulepszania Usługi. Nie będziemy
          wykorzystywać ani udostępniać informacji o użytkowniku nikomu poza
          przypadkami opisanymi w niniejszej Polityce Prywatności.
        </p>

        <p>
          Terminy użyte w niniejszej Polityce Prywatności mają takie samo
          znaczenie, jak w naszym{" "}
          <Link to="/terms-and-conditions">
            Regulaminie korzystania z aplikacji
          </Link>
          , chyba że w niniejszej Polityce Prywatności zdefiniowano inaczej.
        </p>

        <h2>Zbieranie i wykorzystywanie informacji</h2>

        <p>
          Dla lepszego doświadczenia, podczas korzystania z naszego Serwisu,
          możemy wymagać od Ciebie podania nam pewnych informacji, które pozwolą
          na identyfikację Twojej osoby. Informacje, o które poprosimy, zostaną
          przez nas zachowane i wykorzystane w sposób opisany w niniejszej
          polityce prywatności.
        </p>
        <p>
          Aplikacja korzysta z usług stron trzecich, które mogą zbierać
          informacje pozwalające na identyfikację użytkownika.
        </p>

        <p>
          Link do polityki prywatności usługodawców zewnętrznych, z których
          korzysta aplikacja
        </p>
        <ul>
          <li>
            <a href="https://policies.google.com/terms" rel="nofollow">
              Google Play Services
            </a>
          </li>
          <li>
            <a
              href="https://firebase.google.com/terms/analytics"
              rel="nofollow"
            >
              Google Analytics for Firebase
            </a>
          </li>
          <li>
            <a
              href="https://firebase.google.com/terms/crashlytics"
              rel="nofollow"
            >
              Firebase Crashlytics
            </a>
          </li>
          <li>
            <a href="https://sentry.io/terms/" rel="nofollow">
              Sentry
            </a>
          </li>
        </ul>
        <h2>Logowanie danych</h2>

        <p>
          Informujemy, że podczas korzystania z naszej Usługi, w przypadku
          wystąpienia błędu w aplikacji, zbieramy na telefonie użytkownika dane
          i informacje (za pośrednictwem produktów innych firm) zwane Logami.
          Logi mogą zawierać takie informacje, jak adres protokołu internetowego
          ("IP") urządzenia, nazwa urządzenia, wersja systemu operacyjnego,
          konfiguracja aplikacji podczas korzystania z Usługi, godzina i data
          korzystania z Usługi oraz inne dane statystyczne.
        </p>

        <h2>Pliki cookie</h2>

        <p>
          Pliki cookie to pliki zawierające niewielką ilość danych, które są
          powszechnie używane jako anonimowe, niepowtarzalne identyfikatory. Są
          one przesyłane do przeglądarki Użytkownika z odwiedzanych przez niego
          stron internetowych i przechowywane w pamięci wewnętrznej urządzenia.
        </p>

        <p>
          Serwis nie korzysta bezpośrednio z takich plików "cookie". Aplikacja
          może jednak korzystać z kodu i bibliotek stron trzecich, które
          wykorzystują pliki "cookie" do zbierania informacji i ulepszania
          swoich usług. Użytkownik ma możliwość zaakceptowania lub odrzucenia
          tych plików cookie i wie, kiedy są one wysyłane do jego urządzenia.
          Jeśli użytkownik zdecyduje się odrzucić nasze pliki cookie, może nie
          być w stanie korzystać z niektórych części niniejszej Usługi.
        </p>
        <h2> Dostawcy usług</h2>
        <p>Możemy zatrudniać firmy i osoby trzecie z następujących powodów: </p>
        <ul>
          <li>W celu ułatwienia korzystania z naszej Usługi</li>
          <li>Aby świadczyć Usługę w naszym imieniu</li>
          <li>w celu świadczenia usług związanych z Usługą; lub</li>
          <li>Aby pomóc nam w analizie sposobu korzystania z naszej Usługi</li>
        </ul>
        <p>
          Pragniemy poinformować użytkowników Usługi, że osoby trzecie mają
          dostęp do ich Danych Osobowych. Powodem tego jest wykonywanie zadań
          powierzonych im w imieniu Chartis. Są one jednak zobowiązane do
          nieujawniania ani niewykorzystywania tych informacji w żadnym innym
          celu.{" "}
        </p>
        <h2>Bezpieczeństwo</h2>
        <p>
          Cenimy sobie Państwa zaufanie, jakim obdarzają nas Państwo przekazując
          nam swoje Dane osobowe, dlatego też staramy się stosować komercyjnie
          akceptowalne środki ich ochrony. Należy jednak pamiętać, że żadna
          metoda transmisji przez Internet ani żadna metoda elektronicznego
          przechowywania danych nie jest w 100% bezpieczna i niezawodna, a my
          nie możemy zagwarantować ich całkowitego bezpieczeństwa.
        </p>
        <h2>Łącza do innych witryn</h2>
        <p>
          Serwis może zawierać łącza do innych witryn. Jeśli użytkownik kliknie
          na łącze innej strony, zostanie przekierowany na tę stronę. Należy
          pamiętać, że te zewnętrzne witryny nie są przez nas obsługiwane.
          Dlatego zalecamy zapoznanie się z polityką prywatności tych witryn.
          Nie mamy kontroli nad treścią, polityką prywatności ani praktykami
          stosowanymi przez witryny lub usługi innych firm i nie ponosimy za nie
          odpowiedzialności.
        </p>
        <h2>Prywatność dzieci</h2>
        <p>
          Usługi te nie są skierowane do osób poniżej 13 roku życia. Nie
          gromadzimy świadomie informacji umożliwiających identyfikację osób od
          dzieci poniżej 13 roku życia. W przypadku odkrycia, że dziecko poniżej
          13. roku życia przekazało nam dane osobowe, natychmiast usuwamy je z
          naszych serwerów. Jeśli jesteś rodzicem lub opiekunem i wiesz, że
          Twoje dziecko przekazało nam dane osobowe, skontaktuj się z nami,
          abyśmy mogli podjąć odpowiednie działania.
        </p>
        <h2>Zmiany w Polityce prywatności</h2>
        <p>
          Od czasu do czasu możemy aktualizować naszą Politykę Prywatności.
          Dlatego zaleca się okresowe przeglądanie tej strony w celu zapoznania
          się z ewentualnymi zmianami. Powiadomimy Cię o wszelkich zmianach,
          publikując nową Politykę Prywatności na tej stronie. Niniejsze zasady
          obowiązują od dnia 2022-03-24.
        </p>
        <h2>Kontakt z nami</h2>
        <p>
          Jeśli masz jakiekolwiek pytania lub sugestie dotyczące naszej Polityki
          Prywatności, skontaktuj się z nami pod adresem{" "}
          <a href="mailto:contact@beautifulplaces.top">
            contact@beautifulplaces.top
          </a>
          .
        </p>
      </div>
      <Footer />
    </Page>
  );
};

export default PrivacyPolicy;
